const announcements = [
    {
        id:1,
        title:"DISCLAIMER: BEWARE OF FAKE MUTEMWA BANK ACCOUNTS",
        date:"22 May 2023 ",
        from:"Management",
        message:"Beware of fake Mutemwa bank accounts or people who claim to be from Mutemwa Leprosy Centre and Care Centre organisation."
    },{
        id:2,
        title:"To All Parishioners: REF: INVITATION TO THE 44th ANNIVERSARY CELEBRATIONS OF JOHN.R. BRADBURNE  ",
        date:"09 May 2023",
        from:"Chaplin",
        message:`I write on behalf of Mutemwa Leprosy Catholic and Care Centre cordially inviting you all to the John Bradburne 44th Anniversary to be held on the 2ND and 3RD   of September 2023 at MUTEMWA in Mutoko. The program starts at 1500 hours and end the following day around 1000 hours.
        This is a time we thank God and pray as a family as well as remembering the great works done by the Servant of God the late John Bradburne. 
        Our Theme is “John Bradburne cause for sainthood is still on”
        We would like to thank you for your continued support with various donations and they have kept us going and enabled us to run the institution well.   
        1. ECO CASH MERCHANT CODE *151* 2* 2* 55461* AMOUNT #
        2. AFC-BANK RTGS A/C……………………….100001102936
        3. AFC-BANK FCA A/C…....................................100010474568	
        John Bradburne material is on sale at the Archdiocese of Harare Bishop's office reception. 
        For further information, may you please contact the Administrator - 0773440730
        Your usual support towards this event is highly appreciated.` 
    },

]

export default announcements;